import React from 'react';
import styled from 'styled-components';
import {
    Link
} from 'react-router-dom';
import { motion } from "framer-motion"
import { Footer } from '../components/Footer';

const Header = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const LogoImg = styled.img`
    height: 30vmin;
    pointer-events: none;
    margin-bottom: 20px;
    width: 30vmin; /* Makes the image width equal to its height */
    pointer-events: none;
    margin-bottom: 20px;
    background: white;
    border-radius: 32px; /* Rounded corners for the square */
    padding: 22px; /* Adds padding around the image */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const StoreLink = styled.a`
  img {
    height: 40px; // Set a fixed height
    width: auto;  // Maintain aspect ratio
    margin: 10px;
  }
`;


function HomePage() {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        },
    };

    return (
        <>
            <Header>
                <LogoImg src="https://firebasestorage.googleapis.com/v0/b/bundora-7068e.appspot.com/o/wool.png?alt=media&token=fcc83c35-6b53-4a6f-84dc-dd0641e6dcbe" alt="Tant" />
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.h1 variants={itemVariants}>Tant</motion.h1>
                    <motion.p variants={itemVariants}>Download the app on App Store or Google Play</motion.p>
                    <motion.div variants={itemVariants}>
                        <StoreLink href="https://apps.apple.com" target="_blank" without rel="noreferrer" >
                            <img src="https://firebasestorage.googleapis.com/v0/b/bundora-7068e.appspot.com/o/app-store.png?alt=media&token=06536917-e8cb-4585-97cd-5ef7268e66d1" alt="Download on the App Store" />
                        </StoreLink>
                        <StoreLink href='https://play.google.com/store' target="_blank" without rel="noreferrer" >
                            <img src="https://firebasestorage.googleapis.com/v0/b/bundora-7068e.appspot.com/o/google-play.png?alt=media&token=8330792c-a79b-43dc-bea6-e8898158107d" alt="Download on the Google Play Store" />
                        </StoreLink>
                    </motion.div>
                </motion.div>

            </Header>
            <Footer color='white'>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <p>© 2023 <a href="https://nathalierautell.com" target="_blank" without rel="noreferrer" title="rautellinn.com">rautellinn.com</a> Illustrations created by <a href="https://www.flaticon.com/authors/surfsupvector" target="_blank" without rel="noreferrer" title="illustrations"> Surfsup.Vector - Flaticon</a></p>
            </Footer>
        </>
    );
}

export default HomePage;
