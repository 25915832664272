import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/PolicyPage';

const AppContainer = styled.div`
  text-align: center;
  background: rgb(137,127,247);
  background: -moz-linear-gradient(6deg, rgba(137,127,247,1) 22%, rgba(188,182,250,1) 45%, rgba(255,255,255,1) 85%);
  background: -webkit-linear-gradient(6deg, rgba(137,127,247,1) 22%, rgba(188,182,250,1) 45%, rgba(255,255,255,1) 85%);
  background: linear-gradient(6deg, rgba(137,127,247,1) 22%, rgba(188,182,250,1) 45%, rgba(255,255,255,1) 85%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#897ff7",endColorstr="#ffffff",GradientType=1);
`;

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<AppContainer><HomePage /></AppContainer>} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
        </Routes>
    </Router>
  );
}

export default App;
