import styled from 'styled-components';

export const Footer = styled.footer`
  color: ${(props) => props.color};
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;

  a {
    color: ${(props) => props.color};
    text-decoration: none;

    &:hover {
        color: #FFA800;
    }
  }
  
  p {
    margin: 0;
  }
`;

