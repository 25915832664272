import React from 'react';
import { Link } from 'react-router-dom';
import { Footer  } from '../components/Footer';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  h2 {
    color: #282c34;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: #FFA800;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

function PrivacyPage() {
    return (
        <>
            <PrivacyPolicyContainer>
                <h2>Privacy Policy for Tant</h2>
                <p>Last Updated: 2023-11-11</p>
                <ul>
                    <li>
                        <strong>Introduction</strong><br />
                        Welcome to Tant. This privacy policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                    </li>
                    <li>
                        <strong>Data Collection and Use</strong><br />
                        We are committed to respecting your privacy. Our app:<br />
                        - Does not collect any personal data.<br />
                        - Does not communicate over the internet.
                    </li>
                    <li>
                        <strong>Information Security</strong><br />
                        We are dedicated to protecting the security of our users. As our app does not collect or transmit any data, your personal information remains secure and private.
                    </li>
                    <li>
                        <strong>Changes to This Privacy Policy</strong><br />
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </li>
                    <li>
                        <strong>Contact Us</strong><br />
                        If you have any questions about this Privacy Policy, please <a href="mailto:support@rautellinn.com">contact us</a>.
                    </li>
                </ul>
            </PrivacyPolicyContainer>
            <Footer color='black'>
                <Link to="/">Home</Link>
            </Footer>
        </>
    );
}

export default PrivacyPage;
